import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import './DropdownMenuSport.css';

const DropdownMenu = () => {

  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div className="dropdown" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <NavLink to="#" className="nav-link">
        SPORTS
      </NavLink>
      {isOpen && (
        <div className="dropdown-menu">
          <NavLink to="/sports" className="dropdown-item" style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })}>Les sports</NavLink><br></br>
          <NavLink to="/edt" className="dropdown-item" style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })}>Emploi du temps</NavLink><br></br>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
