import React from "react";

interface ScreenProps {
  isFr: boolean;
  title: string;
  img: string;
  desc: string;
  link: string;
  date: string;
}


const NextEvent: React.FC<ScreenProps> = ({ isFr, title, img, desc, link, date }) => {
  return (
    <div className="min-h-96 shadow-2xl rounded-lg max-w-md border border-black-400">
      <img
        src={process.env.PUBLIC_URL + "/images/" + img}
        className="rounded-t-lg border-b-4 border-red-700 mb-2"
      />
      <div className="p-4">
        <div className="border-b pb-2 border-gray-300 flex flex-row justify-between mb-2">
          <h2 className="font-semibold text-3xl ">{title}</h2>
          <p className="text-gray-700 font-extralight text-xl">{date}</p>
        </div>
        <p className="text-gray-700 font-light text-lg mb-3">{desc}</p>
        <div className="flex flex-row justify-end">
          <span><a href={link}>{isFr ? "Plus d'informations" : "More information"}</a></span>
        </div>
      </div>
    </div>
  );
}

export default NextEvent;
