import React from 'react';
import './Sport_card.css';

interface ScreenProps {
  isFr: boolean;
  sport: string;
  img_src: string;
}

export const SportCard: React.FC<ScreenProps> = ({ isFr, sport, img_src }) => {
  const imageUrl = `${process.env.PUBLIC_URL}/images/${img_src}`;  // Construct the URL for the uploaded image
  return (
    <div className='sport-card'>
      <img src={imageUrl} alt={sport} className='sport-image' />
      <div className='sport-info'>
        <h3 className='sport-name'>{sport}</h3>
      </div>
    </div>
  );
};
