import React, { FormEvent, useEffect, useState } from 'react';
import axios from 'axios';

type Editeventform = {
    id: string
}

const EditEventForm: React.FC<Editeventform> = ({id}) => {
  const [nom, setNom] = useState('');
  const [date_debut, setDate_debut] = useState('');
  const [date_fin, setDate_fin] = useState('');
  const [lieu, setLieu] = useState('');
  const [details, setDetails] = useState('');
  const [details_short, setDetails_short] = useState('');
  const [prix_cotisant, setPrix_cotisant] = useState('');
  const [prix_non_cotisant, setPrix_non_cotisant] = useState('');
  const [event_de_billetterie, setEvent_de_billetterie] = useState(false);
  const [lien_cagnotte, setLien_cagnotte] = useState('');

  
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const newEvent = { nom: nom, date_debut: date_debut, date_fin: date_fin, lieu:lieu, details_short, details, prix_cotisant, prix_non_cotisant, event_de_billetterie, lien_cagnotte:lien_cagnotte };
      await axios.post('/api/events/update', { id, ...newEvent});
      alert('Event updated successfully');
    } catch (err) {
      alert('Error adding event');
    }
  };

  useEffect(() => {
    axios.get(`/api/events/${id}`)
                .then((event) => {console.log("on reçoit "+event) ; setNom(event.data.nom) ; setDate_debut(event.data.date_debut.split('T')[0]); 
                  setDate_fin(event.data.date_fin.split('T')[0]); setLieu(event.data.lieu); 
                  setDetails(event.data.details) ; setDetails_short(event.data.details_short); 
                  setPrix_cotisant(event.data.prix_cotisant); setPrix_non_cotisant(event.data.prix_non_cotisant); 
                  setEvent_de_billetterie(event.data.event_de_billetterie); setLien_cagnotte(event.data.lien_cagnotte);})
                .catch((error) => console.log(error));
    }, []);

  return (
    <main>
    <form onSubmit={handleSubmit} className='max-w-md mx-auto bg-white p-8 border border-gray-300 rounded-lg shadow-lg space-y-4'>
      <h3 className='font-semibold text-lg'>Ajouter un event</h3>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Nom</label>
        <input type="text" value={nom} onChange={(e) => setNom(e.target.value)} required className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'/>
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Date de début</label>
        <input type="date" value={date_debut} onChange={(e) => setDate_debut(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Date de fin (pas utile si c'est la meme que la date de début)</label>
        <input type="date" value={date_fin} onChange={(e) => setDate_fin(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Lieu</label>
        <input type="text" value={lieu} onChange={(e) => setLieu(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Prix pour les cotisants</label>
        <input type="number" value={prix_cotisant} onChange={(e) => setPrix_cotisant(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Prix pour les non-cotisants</label>
        <input type="number" value={prix_non_cotisant} onChange={(e) => setPrix_non_cotisant(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Détails</label>
        <input type="text" value={details} onChange={(e) => setDetails(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Détails (version courte)</label>
        <input type="text" value={details_short} onChange={(e) => setDetails_short(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Événement à afficher dans la billetterie</label>
        <div className="mt-1 flex items-center space-x-4">
          <label className="inline-flex items-center">
            <input
              type="radio"
              name="event_de_billetterie"
              value="true"
              onChange={() => setEvent_de_billetterie(true)}
              className="form-radio text-blue-500"
            />
            <span className="ml-2">Oui</span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              name="event_de_billetterie"
              value="false"
              onChange={() => setEvent_de_billetterie(false)}
              className="form-radio text-blue-500"
            />
            <span className="ml-2">Non</span>
          </label>
        </div>
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Lien de la cagnotte (si affiché dans la billetterie)</label>
        <input type="text" value={lien_cagnotte} onChange={(e) => setLien_cagnotte(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>

      <button type="submit" className='w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200'>Modifier Event</button>
    </form>
    </main>
  );
};

export default EditEventForm;
