import React from 'react'

interface ScreenProps{
    isFr: boolean;
    SwitchFrEn: ()=>void;
    isConnected: boolean;
    SwitchConnection: ()=>void;
    queryParams: URLSearchParams;
  }

  /*
  *            <main>
                <h3>Cliquez <a href="">ici</a> pour accéder à la cagnotte permettant de cotiser au BDS</h3>
            </main>
  */

export const CotisationPage: React.FC<ScreenProps> = ({isFr, SwitchFrEn, isConnected, SwitchConnection, queryParams}) => {
 
        return (
            <div className='h-screen top-0 flex justify-center'>
                
            <div className='shadow-lg w-2/3 rounded-lg h-4/5 mt-24'>
                {/*<img className='rounded-t-lg border-b-4 border-red-800' src={process.env.PUBLIC_URL + "/images/arkose_pas_de_droits.jpg"} alt="" />*/}
                <div className='p-4'>

                    <h1 className='font-semibold text-3xl'>
                        Cotiser au <span className='text-red-700'>BDS</span> de Télécom Paris
                    </h1>
                    <p className='pb-2 mb-3 font-light text-gray-500 border-b border-gray-300'>
                        {isFr ? "Passé Septembre, l'accès aux différents sports proposé nécessite d'avoir cotisé" : "From the end of September, you will need to be a member of the association to be able to access the different sports"}
                    </p>
                    <p className='font-light text-lg'>
                        {isFr ? "Tu peux t'inscrire à n'importe quel moment de l'année et ta cotisation te permet d'avoir des réductions sur certains événements sportifs tels que des places pour voir des matchs professionnels ! " : "You can pay your membership at anytime during the school year, and it allows you to get discounts on many events such as tickets for matches !"} 
                    </p>
                </div>
                <div className="flex justify-center">

                    <a href="https://collecte.io/cotisations-bds-2024-2025-7518521/fr" className='bg-red-800 text-white p-2 text-2xl rounded-lg hover:bg-red-700 transition-colors'>Prendre sa cotisation</a>
                </div>
            </div>
        </div>

        )
}