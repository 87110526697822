import React, { FormEvent, useState } from 'react';
import axios from 'axios';

const AddSportForm = () => {
  const [namefr, setNamefr] = useState('');
  const [nameen, setNameen] = useState('');
  const [respo, setRespo] = useState('');
  const [descriptionfr, setDescriptionfr] = useState('');
  const [descriptionen, setDescriptionen] = useState('');
  const [horairefr, setHorairefr] = useState('');
  const [horaireen, setHoraireen] = useState('');
  const [image, setImage] = useState<File | null>(null);  // Re-enable image state
  const [localisation, setLocalisation] = useState('');
  const [loc_lien, setLoc_lien] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const toggleForm = () => {
    setIsVisible(!isVisible);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('namefr', namefr);
    formData.append('nameen', nameen);
    formData.append('respo', respo);
    formData.append('descriptionfr', descriptionfr);
    formData.append('descriptionen', descriptionen);
    formData.append('horairefr', horairefr);
    formData.append('horaireen', horaireen);
    formData.append('localisation', localisation);
    formData.append('loc_lien', loc_lien);
    if (image) {
      formData.append('image', image);  // Append the image file
    }

    try {
      await axios.post('/api/sports/update', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Sport added successfully');
      setNamefr('');
      setNameen('')
      setRespo('');
      setDescriptionfr('');
      setDescriptionen('');
      setHorairefr('');
      setHoraireen('');
      setLocalisation('');
      setLoc_lien('');
      setImage(null);  // Reset image state
    } catch (err) {
      console.error(err);
      alert('Error adding sport');
    }
  };

  return (
    <main>
    <button className='text-lg px-6 py-2 my-3 text-white font-semibold bg-blue-500 rounded-lg shadow-md hover:bg-blue-600 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 transition duration-200' onClick={toggleForm}>Ajouter un nouveau sport</button>
    {isVisible && <form onSubmit={handleSubmit} className='max-w-md mx-auto bg-white p-8 border border-gray-300 rounded-lg shadow-lg space-y-4'>
      <h3 className='font-semibold text-lg'>Ajouter un nouveau sport</h3>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Nom français</label>
        <input type="text" value={namefr} onChange={(e) => setNamefr(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Nom anglais</label>
        <input type="text" value={nameen} onChange={(e) => setNameen(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Responsable</label>
        <input type="text" value={respo} onChange={(e) => setRespo(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Description française</label>
        <textarea value={descriptionfr} onChange={(e) => setDescriptionfr(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Description anglais</label>
        <textarea value={descriptionen} onChange={(e) => setDescriptionen(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Horaire français</label>
        <textarea value={horairefr} onChange={(e) => setHorairefr(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Horaire anglais</label>
        <textarea value={horaireen} onChange={(e) => setHoraireen(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Localisation</label>
        <textarea value={localisation} onChange={(e) => setLocalisation(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Lien maps</label>
        <textarea value={loc_lien} onChange={(e) => setLoc_lien(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Image</label>
        <input 
          type="file" 
          onChange={(e) => setImage(e.target.files![0])}  // Handle file input
          accept="image/*"  // Restrict to image files
          required 
          className='mt-1 block w-full border border-gray-300 rounded-lg cursor-pointer focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
        />
      </div>
      <button type="submit" className='w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200'>Ajouter Sport</button>
    </form>}
    </main>
  );
};

export default AddSportForm;
