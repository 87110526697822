import React from 'react';
import Popup from 'reactjs-popup';
import { SportCard } from '../components/Sport_card';
import './SportPopup.css';
interface Sport {
  namefr: string;
  nameen: string,
  respo: string;
  descriptionfr: string;
  descriptionen: string,
  horairefr: string;
  horaireen: string,
  localisation: string;
  loc_lien: string;
  img_src: string;
}

interface SportPopupProps {
  isFr: boolean;
  isConnected: boolean;
  sport: Sport;
}

export const SportPopup: React.FC<SportPopupProps> = ({ isFr, isConnected, sport }) => {
  
  return (
    <Popup 
      trigger={<div><SportCard isFr={isFr} sport={isFr ? sport.namefr : sport.nameen} img_src={sport.img_src} /></div>} 
      modal 
      on="click" 
      position="center center"
    >
      <div className="popup-content">
        <img src={`${process.env.PUBLIC_URL}/images/${sport.img_src}`} alt={sport.namefr} className="popup-image" />
        <div className="popup-details">
          <h1>{isFr ? sport.namefr : sport.nameen}</h1>
          <h2>{isFr ? 'Responsable' : 'Leader'}</h2>
          <p>{sport.respo}</p>
          {sport.descriptionfr.length > 0 && (<h2>Description</h2>)}
          <p>{isFr ? sport.descriptionfr : sport.descriptionen}</p>
          <h2>{isFr ? 'Horaires' :'Hours'}</h2>
          <p>{isFr ? sport.horairefr: sport.horaireen}</p>
          <h2>{isFr ? 'Localisation' : 'Location'}</h2>
          <p>{sport.localisation}</p>
          <a href = {sport.loc_lien}>{isFr ? 'Voir sur Google maps' : 'See on Google Maps'}</a>
        </div>
      </div>
    </Popup>
  );
};

// TODO: donner numéro de tel des respos quand on hover ou clique sur leur nom
