import React, { FormEvent, useState } from 'react';
import axios from 'axios';

interface ScreenProps {
  setLoggedIn: (f:boolean) => void;
}

const AdminLogin: React.FC<ScreenProps> = ({ setLoggedIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/login', { username, password }, { withCredentials: true });
      if (response.data.message === 'Login successful') {
        localStorage.setItem('isLoggedIn', 'true'); // Store login state in local storage
        setLoggedIn(true);
      }
    } catch (err) {
      alert('Login failed');
    }
  };

  return (
    <div className='flex items-center justify-center min-h-screen bg-gray-100'>
      <form onSubmit={handleSubmit} className=' max-w-md mx-auto bg-white p-8 border border-gray-300 rounded-lg shadow-lg space-y-4'>
        <h3 className='font-semibold text-xl text-center'>Admin Login</h3>
        <div className="text-center">
          <label className='block text-sm font-medium text-gray-700'>Username</label>
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value) } className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
        </div>
        <div className="text-center">
          <label className='block text-sm font-medium text-gray-700'>Password</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
        </div>
        <button type="submit" className='w-full px-6 py-2 my-3 text-white font-semibold bg-blue-500 rounded-lg shadow-md hover:bg-blue-600 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 transition duration-200'>Login</button>
      </form>
    </div>
  );
};

export default AdminLogin;
