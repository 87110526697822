import React from 'react';

interface ScreenProps{
    isFr: boolean;
    SwitchFrEn: ()=>void;
    isConnected: boolean;
    SwitchConnection: ()=>void;
    queryParams: URLSearchParams;
  }

export const Footer: React.FC<ScreenProps> = ({isFr, SwitchFrEn, isConnected, SwitchConnection} ) => {    
    return (
        <>
            <footer className='bottom-0 flex justify-between w-full p-2 text-sm bg-red-700 text-red-100'>
            <img className='w-12 h-12' src={process.env.PUBLIC_URL+ '/images/logobds.png'} alt='logo du BDS'/>
            <div>
                <h4>À propos</h4>
                <p>Retrouvez ici toutes les informations et outils du BDS de Télécom Paris</p></div>
            <div>
                <h4>Contact</h4>
                <p>BDS Télécom Paris, 19 Place Marguerite Perey, 91120 Palaiseau, France</p>

            </div>
            <div>
                <h4>Sponsors</h4>
                <div>
                    {/*<a href='https://particuliers.sg.fr/' target='_blank' className='font-semibold'>Société Générale</a>*/}
                    <a href='https://particuliers.sg.fr/' target='_blank' className='font-semibold'>
                    <img src={process.env.PUBLIC_URL + '/images/logo_sg_titre.svg'} alt="Logo Société  Générale"></img>
                    </a>
                </div>
            </div>
            </footer>
        </>
    )
}