import React, { FormEvent, useState } from 'react';
import axios from 'axios';

const AddCotisantForm = () => {

  const [isVisible, setIsVisible] = useState(false);
  const toggleForm = () => {
    setIsVisible(!isVisible);
  };

  const [name, setName] = useState('');
  const [duration, setDuration] = useState('');
  const [promotion, setPromotion] = useState('');

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const newCotisant = { name, duration, promotion };
      await axios.post('/api/cotisations', newCotisant);
      alert('Cotisant added successfully');
      setName('');
      setDuration('');
      setPromotion('');
    } catch (err) {
      alert('Error adding cotisant');
    }
  };

  return (
    <main>
      <button className='text-lg px-6 py-2 my-3 text-white font-semibold bg-blue-500 rounded-lg shadow-md hover:bg-blue-600 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 transition duration-200' onClick={toggleForm}>Ajouter un nouveau cotisant</button>
      {isVisible && (<form onSubmit={handleSubmit} className='max-w-md mx-auto bg-white p-8 border border-gray-300 rounded-lg shadow-lg space-y-4'>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Nom</label>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Durée (années)</label>
        <input type="number" value={duration} onChange={(e) => setDuration(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Promo</label>
        <input type="number" value={promotion} onChange={(e) => setPromotion(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <button type="submit" className='w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200'>Ajouter Cotisant</button>
    </form>)}
    </main>
  );
};

export default AddCotisantForm;
