import axios from "axios";
import React, { useEffect, useState } from "react";
import NextEvent from "./NextEvent";

interface ScreenProps{
    isFr: boolean;
  }


type Event = {
    nom: string;
    date_debut: Date;
    date_fin: Date;
    heure_debut: string;
    heure_fin: string;
    lieu: string;
    details_short: string;
    details: string;
    prix_cotisant: number;
    prix_non_cotisant: number;
    event_de_billetterie: boolean;
    lien_cagnotte: string;
};

const NextEventList:React.FC<ScreenProps> = ({isFr}) => {
    const current_date = new Date()
    const [events,setEvents] = useState([])

    axios.get('/api/events/')
        .then((events) => {console.log(new Date(events.data[0].date_debut).getTime() > current_date.getTime()) ; setEvents(events.data.filter((event:Event) => new Date(event.date_debut).getTime() >= current_date.getTime() ))})
        .catch(error => console.log(error));

    const first_events = events.slice(0, Math.min(3,events.length));
    return (
    <main>
        <div>
        {events.length > 0 && <h2 className="font-semibold m-5">Événements qui arrivent prochainement</h2>}
        {first_events.map((event:Event) => <NextEvent isFr={isFr} title={event.nom} img="" desc={event.details} link={event.lien_cagnotte} date={new Date(event.date_debut).toISOString().split('T')[0]}/>)}
        </div>
    </main>)
}

export default NextEventList;