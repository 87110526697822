import React, { FormEvent, useEffect, useState } from 'react';
import axios from 'axios';

type Editcotisantform = {
    id: string
}

const EditCotisantForm: React.FC<Editcotisantform> = ({id}) => {


  const [name, setName] = useState('');
  const [duration, setDuration] = useState('');
  const [promotion, setPromotion] = useState('');

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.post('/api/cotisations/update', { id, name, duration, promotion});
      alert('Cotisant updated successfully');
    } catch (err) {
      alert('Error editing cotisant');
    }
  };

  useEffect(() => {
  const data = axios.get(`/api/cotisations/${id}`)
                        .then((cotisant) => {console.log("on reçoit "+cotisant) ; setName(cotisant.data.name) ; setDuration(cotisant.data.duration); setPromotion(cotisant.data.promotion);})
                        .catch((error) => console.log(error));
  }, []);

  return (
    <main>
      <form onSubmit={handleSubmit} className='max-w-md mx-auto bg-white p-8 border border-gray-300 rounded-lg shadow-lg space-y-4'>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Nom</label>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Durée (années)</label>
        <input type="number" value={duration} onChange={(e) => setDuration(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Promo</label>
        <input type="number" value={promotion} onChange={(e) => setPromotion(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <button type="submit" className='w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200'>Modifier</button>
    </form>
    </main>
  );
};

export default EditCotisantForm;
