import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from "react-oidc-context";


function onSigninCallback(_user: any): void {
  window.history.replaceState(
    {},
    document.title,
    window.location.origin + window.location.pathname
  );

  //       window.location.href = window.location.origin + window.location.pathname;
}

const oidcConfig = {
  authority: "https://garezeldap.rezel.net/keycloak/auth/realms/master/",
  client_id: 'bds',
  redirect_uri: 'http://localhost:3000/connexion',
  secret: 'a8a828ed-dfef-48a6-bb55-2fe77e3a3d3b',
  scope: "email profile roles web-origins",
  onSigningCallback: onSigninCallback,
};


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AuthProvider {...oidcConfig}>
    <BrowserRouter>
        <App />
      </BrowserRouter>
  </AuthProvider>
);