import React from 'react';
import './App.css';
import { Routes, Route, useSearchParams } from 'react-router-dom';
import { Home } from './pages/Home';
import { Arkose } from './pages/Arkose';
import { Billetterie } from './pages/Billetterie';
import { Sports } from './pages/Sports';
import { Organigramme } from './pages/Organigramme';
//import { TestsLydia } from './pages/TestsLydia';
import { Menu } from './components/Menu'
import { Footer } from './components/Footer';
import { useState } from 'react';
import { Connexion } from './pages/Connexion';
import { SportsEdt } from './pages/SportsEdt';
import AdminPage from './pages/AdminPage';
import CalendarPage from './pages/CalendarPage';
import { CotisationPage } from './pages/CotisationPage';

function App() {
  const [isFr, setIsFr] = useState(true);
  const [queryParams] = useSearchParams();

  const switchFrEn = () => {
    setIsFr(!isFr);
  }

  const [cotisation, setCotisation] = useState(true);

  const switchCotisation = () => {
    setCotisation(!cotisation);
  }

  const [isConnected, setIsConnected] = useState(true);

  const switchConnection = () => {
    setIsConnected(!isConnected);
  }
  return (
    <div className="bg-red-50/20 flex flex-col justify-between h-screen">
      <Menu isFr={isFr} SwitchFrEn={switchFrEn} isConnected={isConnected} SwitchConnection={switchConnection} queryParams={queryParams}></Menu>
      <main className="">
        <div>
          <Routes>
            <Route path='/' element={<Home isFr={isFr} SwitchFrEn={switchFrEn} isConnected={isConnected} SwitchConnection={switchConnection} queryParams={queryParams} />} />
            <Route path='/sports' element={<Sports isFr={isFr} SwitchFrEn={switchFrEn} isConnected={isConnected} SwitchConnection={switchConnection} queryParams={queryParams} />} />
            <Route path='/arkose' element={<Arkose isFr={isFr} SwitchFrEn={switchFrEn} isConnected={isConnected} SwitchConnection={switchConnection} queryParams={queryParams} cotisation={cotisation} />} />
            {/*<Route path='/billetterie' element={<Billetterie isFr={isFr} SwitchFrEn={switchFrEn} isConnected={isConnected} SwitchConnection={switchConnection} queryParams={queryParams} />} />*/}
            <Route path="/organigramme" element={<Organigramme isFr={isFr} SwitchFrEn={switchFrEn} isConnected={isConnected} SwitchConnection={switchConnection} queryParams={queryParams} />} />
            {/*<Route path='/connexion' element={<Connexion isFr={isFr} SwitchFrEn={switchFrEn} isConnected={isConnected} SwitchConnection={switchConnection} queryParams={queryParams} cotisation={cotisation} setCotisation={switchCotisation} />} />*/}
            <Route path='/edt' element={<SportsEdt isFr={isFr} SwitchFrEn={switchFrEn} isConnected={isConnected} SwitchConnection={switchConnection} queryParams={queryParams} />} />
            <Route path='/dfutpgqllsalt' element={<AdminPage />} />
            <Route path='/calendar' element={<CalendarPage />} />
            <Route path='/cotisation' element={<CotisationPage isFr={isFr} SwitchFrEn={switchFrEn} isConnected={isConnected} SwitchConnection={switchConnection} queryParams={queryParams}/>} />
          </Routes>
        </div>
      </main>
      <Footer isFr={isFr} SwitchFrEn={switchFrEn} isConnected={isConnected} SwitchConnection={switchConnection} queryParams={queryParams}></Footer>
    </div>
  );
}

export default App;
