import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AdminLogin from './AdminLogin';
import AddCotisantForm from '../components/Forms/AddCotisantForm';
import EditCotisantForm from '../components/Forms/EditCotisantForm';
import AddTransactionForm from '../components/Forms/AddTransactionForm';
import AddEventForm from '../components/Forms/AddEventForm';
import AddSportForm from '../components/Forms/AddSportForm';
import AddCodeArkoseForm from '../components/Forms/AddCodeArkoseForm'
import EditCodeArkoseForm from '../components/Forms/EditCodeArkoseForm'
import Popup from 'reactjs-popup';
import EditSportForm from '../components/Forms/EditSportForm';
import EditEventForm from '../components/Forms/EditEventForm';
import EditTransactionForm from '../components/Forms/EditTransactionForm';

type Cotisant = {
  _id: string;
  name: string;
  duration: number;
  promotion: number
}

type CodeArkose = {
  _id: string;
  code: string;
  uuid: string;
  status: string
}

type Event = {
  _id: string;
  nom: string;
  date_debut: Date;
  date_fin: string;
  heure_debut: string;
  heure_fin: string;
  lieu: string;
  details_short: string;
  details: string;
  prix_cotisant: number;
  prix_non_cotisant: number;
  event_de_billetterie: boolean;
  lien_cagnotte: string;
}

type Sport = {
  _id: string;
  namefr: string;
  nameen: string;
  respo: string;
  descriptionfr: string;
  descriptionen: string;
  horairefr: string;
  horaireen: string;
  img_src: string;
  localisation: string;
  loc_lien: string;
}

type Transaction = {
  _id: string;
  id: string;
  name: string;
  date: string;
  value: string;
  details: string;
  status: string;
}

const AdminPage = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [cotisants, setCotisants] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [events, setEvents] = useState([]);
  const [sports, setSports] = useState([]);
  const [codes, setCodes] = useState([]);

  useEffect(() => {
    // Check if the user is logged in by looking at local storage
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    if (isLoggedIn) {
      // Optionally, you could also verify the session with the server
      setLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    if (loggedIn) {
      axios.get('/api/cotisations').then((res) => setCotisants(res.data));
      axios.get('/api/transactions').then((res) => setTransactions(res.data));
      axios.get('/api/events').then((res) => setEvents(res.data));
      axios.get('/api/sports').then((res) => setSports(res.data));
      axios.get('/api/arkose/codes').then((res) => setCodes(res.data));
    }
  }, [loggedIn]);

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn'); // Clear login state from local storage
    setLoggedIn(false);
  };

  // Function to handle removal of items
  const handleRemoveCotisant = (id: string) => {
    axios.delete(`/api/cotisations/${id}`).then(() => {
      setCotisants(cotisants.filter((cotisant: Cotisant) => cotisant._id !== id));
    });
  };

  const handleRemoveTransaction = (id: string) => {
    axios.delete(`/api/transactions/${id}`).then(() => {
      setTransactions(transactions.filter((transaction: Transaction) => transaction._id !== id));
    });
  };

  const handleRemoveEvent = (id: string) => {
    axios.delete(`/api/events/${id}`).then(() => {
      setEvents(events.filter((event: Event) => event._id !== id));
    });
  };

  const handleRemoveSport = (id: string) => {
    axios.delete(`/api/sports/${id}`).then(() => {
      setSports(sports.filter((sport: Sport) => sport._id !== id));
    });
  };

  const handleRemoveCode = (id: string) => {
    console.log("id qu'on supprime : "+id+" de type" + typeof(id));
    axios.delete(`/api/arkose/codes/${id}`).then(() => {
      setCodes(codes.filter((code: CodeArkose) => code._id !== id));
    });
  };

  const handleEdit = (id: string) => {console.log("id a editer : "+id)}

  return (
    <div>
      {!loggedIn ? (
        <AdminLogin setLoggedIn={setLoggedIn} />
      ) : (
        <div className="main text-center">
          <button onClick={handleLogout} className='px-6 py-2 my-3 text-white font-semibold bg-blue-500 rounded-lg shadow-md hover:bg-blue-600 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 transition duration-200'>Logout</button>
          <h2 className='text-center font-semibold text-3xl'>Admin Dashboard</h2>
          <br></br>
          <br></br>

          <div>
            <h3 className='my-3 font-semibold text-xl'>Cotisants</h3>
            <AddCotisantForm />
            <ul>
              {cotisants.map((cotisant: Cotisant) => (
                <li key={cotisant._id}
                  className='pt-2'
                >
                  {cotisant.name} - Durée: {cotisant.duration} ans - Promo: {cotisant.promotion}
                  <button className='mx-5 text-white font-semibold bg-gray-500 rounded p-1' onClick={() => handleRemoveCotisant(cotisant._id)}>Remove</button>
                  <Popup 
                    trigger={<button className='text-white font-semibold bg-blue-500 rounded p-1' onClick={() => handleEdit(cotisant._id)}>Edit</button>}
                    modal 
                    on="click" 
                    position="center center"
                  >
                    <EditCotisantForm id={cotisant._id}/>
                  </Popup>
                  </li>
              ))}
            </ul>
            <br></br><br></br>
          </div>
        <hr className='border-t border-gray-300 my-4'></hr>
        <br></br>
          <div>
            <h3 className='font-semibold text-xl'>Transactions</h3>
            <AddTransactionForm />
            <ul>
              {transactions.map((transaction: Transaction) => (
                <li key={transaction._id}
                className='pt-2'
              >
                  {transaction.name} - id : {transaction.id} - Date: {new Date(transaction.date).toLocaleDateString()} - Valeur: {transaction.value}€ - Détails: {transaction.details} - Statut : {transaction.status}
                  <button className='mx-5 text-white font-semibold bg-gray-500 rounded p-1' onClick={() => handleRemoveTransaction(transaction._id)}>Remove</button>
                  <Popup 
                    trigger={<button className='text-white font-semibold bg-blue-500 rounded p-1' onClick={() => handleEdit(transaction._id)}>Edit</button>}
                    modal 
                    on="click" 
                    position="center center"
                  >
                    <EditTransactionForm idd={transaction._id}/>
                  </Popup>
                </li>
              ))}
            </ul>
            <br></br><br></br>
          </div>
        <hr className='border-t border-gray-300 my-4'></hr>
        <br></br>

          <div>
            <h3 className='font-semibold text-xl'>Events</h3>
            <AddEventForm />
            <ul>
              {events.map((event: Event) => (
                <li key={event._id}
                className='pt-2'
              >
                  {event.nom} - Date de début : {new Date(event.date_debut).toLocaleDateString()} - Date de fin : {new Date(event.date_fin).toLocaleDateString()} - Lieu: {event.lieu}   
                  <button className='mx-5 text-white font-semibold bg-gray-500 rounded p-1' onClick={() => handleRemoveEvent(event._id)}>Remove</button>
                  <Popup 
                    trigger={<button className='text-white font-semibold bg-blue-500 rounded p-1' onClick={() => handleEdit(event._id)}>Edit</button>}
                    modal 
                    on="click" 
                    position="center center"
                  >
                    <EditEventForm id={event._id}/>
                  </Popup>
                </li>
              ))}
            </ul>
            <br></br><br></br>
          </div>
        <hr className='border-t border-gray-300 my-4'></hr>
        <br></br>

          <div>
            <h3 className='font-semibold text-xl'>Sports</h3>
            <AddSportForm />
            <ul>
              {sports.map((sport: Sport) => (
                <li key={sport._id}
                className='pt-2'
              >
                  {sport.namefr} - Responsable: {sport.respo}
                  <button className='mx-5 text-white font-semibold bg-gray-500 rounded p-1' onClick={() => handleRemoveSport(sport._id)}>Remove</button>
                  <Popup 
                    trigger={<button className='text-white font-semibold bg-blue-500 rounded p-1' onClick={() => handleEdit(sport._id)}>Edit</button>}
                    modal 
                    on="click" 
                    position="center center"
                  >
                    <EditSportForm id={sport._id}/>
                  </Popup>
                </li>
              ))}
            </ul>
            <br></br><br></br>
          </div>
        <hr className='border-t border-gray-300 my-4'></hr>
        <br></br>

          <div>
            <h3 className='font-semibold text-xl'>Codes Arkose</h3>
            <AddCodeArkoseForm />
            <ul>
              {codes.map((code: CodeArkose) => (
                <li key={code._id}
                className='pt-2'
              >
                  {code.code} - UUID : {code.uuid} - Status : {code.status}
                  <button className='mx-5 text-white font-semibold bg-gray-500 rounded p-1' onClick={() => handleRemoveCode(code._id)}>Remove</button>
                  <Popup 
                    trigger={<button className='text-white font-semibold bg-blue-500 rounded p-1' onClick={() => handleEdit(code._id)}>Edit</button>}
                    modal 
                    on="click" 
                    position="center center"
                  >
                    <EditCodeArkoseForm id={code._id}/>
                  </Popup>
                  </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPage;
