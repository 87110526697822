import React, { useEffect, useState } from 'react';
import { SportPopup } from '../components/SportPopup';
import axios from 'axios'; // You can also use fetch if you prefer
import './Sports.css';

interface Sport {
  _id: string;
  namefr: string;
  nameen: string,
  respo: string;
  descriptionfr: string;
  descriptionen: string,
  horairefr: string;
  horaireen: string,
  localisation: string;
  loc_lien: string;
  img_src: string;
}

interface ScreenProps {
  isFr: boolean;
  SwitchFrEn: () => void;
  isConnected: boolean;
  SwitchConnection: () => void;
  queryParams: URLSearchParams;
}

export const Sports: React.FC<ScreenProps> = ({ isFr, SwitchFrEn, isConnected, SwitchConnection, queryParams }) => {
  const [sports, setSports] = useState<Sport[]>([]); // State to store fetched sports
  const [loading, setLoading] = useState(true); // Loading state

  // Fetch sports data when the component mounts
  useEffect(() => {
    const fetchSports = async () => {
      try {
        const response = await axios.get('/api/sports'); // Fetch sports from the API
        setSports(response.data); // Set the fetched sports to state
        setLoading(false); // Stop loading when data is fetched
      } catch (err) {
        console.error('Error fetching sports:', err);
        setLoading(false); // Stop loading even if there's an error
      }
    };

    fetchSports(); // Initial data fetching
  }, []); // Empty dependency array means this runs once on mount

  if (loading) {
    return <p>Loading sports...</p>; // Display a loading message while fetching data
  }

  return (
    <main className="sports-page">
      <br></br>
      <br></br>
      <br></br>
      <h1>{isFr ? "Liste des sports" : "Sports List"}</h1>
      <div className="sports-cards">
        {sports.length > 0 ? (
          sports.sort((sport1, sport2) => sport1.namefr.localeCompare(sport2.namefr)).map((sport) => (
            // Render SportPopup for each sport dynamically
            <SportPopup 
              key={sport._id} 
              isFr={isFr} 
              isConnected={isConnected} 
              sport={sport} 
            />
          ))
        ) : (
          <p>{isFr ? "Aucun sport disponible." : "No sports available."}</p>
        )}
      </div>
    </main>
  );
};
