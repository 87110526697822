import React, { useState } from 'react';

interface ScreenProps {
  isFr: boolean;
  SwitchFrEn: () => void
}


const SwitchLanguage: React.FC<ScreenProps> = ({isFr, SwitchFrEn}) => {

  return (
    <button onClick={SwitchFrEn}>
       <img src={process.env.PUBLIC_URL + `/images/fren.jpeg`} alt="FrEn" className='h-8' />
       </button>
  );
}

export default SwitchLanguage;
