import { access } from 'fs';
import React, { EventHandler, useState, useRef, ChangeEvent, FormEvent, useEffect } from 'react';
import uuid from 'react-uuid'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom';

//  Identifiant public de test : 667d339d3ab5f759924046
//  Identifiant privé de test : 667d339d3ecc8336599229 
// CARTES DE CRÉDIT : 
// [4970109000000007, 5137340014122340];


const WEBSITE_URL = 'http://localhost:3000' // utilisée dans l'adresse de redirection quand on paie



const testing = true;

const api_url = testing ? 'https://homologation.lydia-app.com' : 'https://lydia-app.com'
const vendor_tocken = testing ? '667d339d3ab5f759924046' : '5798ad958652f513784771'
const phone = testing ? "+33600000005" : "+33652678727"



interface ScreenProps {
    isFr: boolean;
    SwitchFrEn: () => void;
    isConnected: boolean;
    SwitchConnection: () => void;
    queryParams: URLSearchParams;
    cotisation: boolean;
}

/*
export const Arkose: React.FC<ScreenProps> = ({ isFr, SwitchFrEn, isConnected, SwitchConnection, queryParams, cotisation }) => {


    // Pour savoir si on arrive sur la page depuis la page d'accueil du site, ou par redirection après validation
    // du paiement Lydia
    const success_status = queryParams.get("success");
    const id = queryParams.get("id");
    const location = useLocation();
    const navigate = useNavigate();
    const [hasNavigated, setHasNavigated] = useState(false);


    const tr_id=useRef('');
    const [accessCode, setAccessCode] = useState('');
    // pour stocker ce qui sera affiché en fonction de si on est cotisant ou pas
    const [output, setOutput] = useState<React.ReactNode>(null)

    // pour afficher le code direct si on vient d'une redirection Lydia
    useEffect(() => {
        if (location.search && !hasNavigated) {
            if (success_status === "true") {
                axios.post('/api/arkose/success', {uuid: id, status: success_status})
                    .then(response => {setOutput("Code : "+response.data.code)})
                    .catch(error => {console.log(error)});
            }   else if (success_status === 'false') {
                axios.post('/api/arkose/cancel', {id: id})
                    .then(response => {console.log('Transaction annulée')})
                    .catch(error => {console.log(error)});
            } 
            
            
            navigate(location.pathname, { replace: true });
            setHasNavigated(true);
        }
        else if (!hasNavigated) {
                showPaymentLink();
            }
    }, [success_status, id, cotisation, isConnected, hasNavigated]);

    
    async function send_payment_request(amount: number, currency: string, vendor_token: string, recipient: string, type: string, uuid: string) {
        const data = new FormData();
        data.append('amount', amount.toString());
        data.append('vendor_token', vendor_token);
        data.append('user_token', ' ');
        data.append('recipient', recipient);
        data.append('currency', currency);
        data.append('type', type);
        data.append('order_ref', uuid);
        data.append('slug', 'test-de-collecte-axel-7508673');
        data.append('browser_success_url', WEBSITE_URL + '/arkose?success=true&id=' + uuid);  // url vers lequel on est redirigé après avoir bien payé
        data.append('end_mobile_url', WEBSITE_URL + '/arkose?success=true&id=' + uuid);
        data.append('browser_fail_url', WEBSITE_URL + '/arkose?success=false&id='+uuid);
        data.append('message','Paiement de code Arkose');

        const requete = await fetch(api_url + "/api/request/do.json", {
            method: 'POST',
            body: data
        });
        return requete.json();
    }

    // si on est cotisant, affiche un lien, sinon affiche un message disant qu'il faut être cotisant
    async function showPaymentLink() {
        if (cotisation) {
            const new_uuid = uuid();
            tr_id.current = new_uuid;
            // TODO: A CHANGER ; REMETTRE 3€50 DANS PAYMENT
            const payment = await send_payment_request(0.1, 'EUR', vendor_tocken, phone, 'phone', new_uuid);
            console.log("url : " + payment.mobile_url);
            console.log("message : " + payment.message);
            console.log("erreur : " + payment.error);
            console.log("order_ref : " + payment.order_ref);


            setOutput(<a href={payment.mobile_url} id="lien_lydia" onClick={() =>initiate_transaction(new_uuid)}>{isFr ? "Payer 3€50" : "Pay 3€50"}</a>);
        }
        else {
            setOutput(<p>{isFr ? "Vous devez être cotisant pour pouvoir demander un code" : "You must be a member of the association to be able to obtain a code"}</p>);
        }

    }

    function initiate_transaction(uuid: string) {
        axios.post('/api/arkose/init', {uuid: uuid})
            .then(response => {console.log(response)})
            .catch(error => {console.log(error)});
        return true;
    }

    if (accessCode !== '' && success_status == 'true') {
        return (<p>Code : {accessCode}</p>);
    }

    if (isConnected) { //pour l'instant pas de connection possible
        return (
            <div className='h-screen top-0 flex justify-center'>
                
                <div className='shadow-lg w-2/3 rounded-lg h-4/5 mt-24'>
                    <img className='rounded-t-lg border-b-4 border-red-800' src={process.env.PUBLIC_URL + "/images/arkose_pas_de_droits.jpg"} alt="" />
                    <div className='p-4'>

                        <h1 className='font-semibold text-3xl'>
                            Obtenir un code <span className='text-red-700'>ARKOSE</span>
                        </h1>
                        <p className='pb-2 mb-3 font-light text-gray-500 border-b border-gray-300'>
                            {isFr ? "Accéder à la salle d'escalade Arkose nécessite un code que nous vous permettons d'acquérir à prix réduit" : "To access Arkose climbing gym, you need a code that we grant you for a reduced price"}
                        </p>
                        <p className='font-light text-lg'>
                            {isFr ? "Pour obtenir un code Arkose, il vous faut payer 3€50 via le lien Lydia suivant :" : "To get an Arkose code, you need to pay 3€50 with the following Lydia link"} 
                        </p>

                        {output}
                    </div>
                </div>
            </div>
        )
    }

    // si on n'est pas connecté ; ne sert pas encore car Rezel pas implémenté
    return (
        <>
            <h1>
                Générateur de code Arkose via Lydia
            </h1>
            <p>
                Connectez-vous pour accéder à ce service
            </p>
        </>
    )
}

*/


export const Arkose: React.FC<ScreenProps> = ({ isFr, SwitchFrEn, isConnected, SwitchConnection, queryParams, cotisation }) => {
    return(
    <main>
        <div className='h-screen top-0 flex justify-center'>
                
                <div className='shadow-lg w-2/3 rounded-lg h-4/5 mt-24'>
                    <img className='rounded-t-lg border-b-4 border-red-800' src={process.env.PUBLIC_URL + "/images/arkose_pas_de_droits.jpg"} alt="" />
                    <div className='p-4'>

                        <h1 className='font-semibold text-3xl'>
                            {isFr ? <>Obtenir un code <span className='text-red-700'>ARKOSE</span></> : <>Get an <span className='text-red-700'>ARKOSE</span> code</>}
                        </h1>
                        <p className='pb-2 mb-3 font-light text-gray-500 border-b border-gray-300'>
                            {isFr ? "Accéder à la salle d'escalade Arkose nécessite un code que nous vous permettons d'acquérir à prix réduit" : "To access Arkose climbing gym, you need a code that we grant you for a reduced price"}
                        </p>
                        <p className='font-light text-lg'>
                            {isFr ? <>Pour obtenir un code vous permettant d'accéder à la salle Arkose, il vous faut payer 3€50 via <a href='https://collecte.io/escalade-3195423/fr' target="_blank" className='text-blue-500 underline'>cette cagnotte</a>, puis demander un code au respo Arkose (Victor Déru).</> : <>To get a code providing access to the Arkose climbing gym, you need to pay 3€50 with <a href='https://collecte.io/escalade-3195423/fr' target="_blank" className='text-blue-500 underline'>this form</a>. Then, ask the 2nd year in charge of Arkose gym (Victor Déru) and he will give you a code.</>}
                        </p>
                        
                    </div>
                </div>
            </div>
    </main>)
}