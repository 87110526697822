import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import DropdownMenu from './DropdownMenuSport';
import SwitchLanguage from './SwitchLanguage';

interface ScreenProps {
  isFr: boolean;
  SwitchFrEn: () => void;
  isConnected: boolean;
  SwitchConnection: () => void;
  queryParams: URLSearchParams;
}

const show_billetterie = false;


export const Menu: React.FC<ScreenProps> = ({ isFr, SwitchFrEn, isConnected, SwitchConnection }) => {
  const [showMenu, setShowMenu] = useState(false); // État pour afficher/cacher le menu mobile
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // État pour afficher/cacher le sous-menu dropdown dans le menu mobile

  const toggleMenu = () => {
    setShowMenu(!showMenu); // Inverse l'état de showMenu pour afficher ou masquer le menu
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Inverse l'état de isDropdownOpen pour le sous-menu
  };

  return (
    <>
      {/* Menu pour les grands écrans */}
      <nav className='bg-red-800 text-red-100 flex justify-between pl-4 pr-4 text-xl font-medium fixed w-full z-10 hidden md:flex'>
        <div className='logobdstelecom flex'>
          <Link to={"/"}><img src={process.env.PUBLIC_URL + '/images/logobds.png'} alt="logo BDS Télécom PARIS" className='w-16 h-16 mx-2'/></Link>
          <SwitchLanguage isFr={isFr} SwitchFrEn={SwitchFrEn} />
        </div>
        <div className='flex flex-col justify-center'>
          <div className='flex justify-around *:ml-2'>
            <NavLink style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })} to='/'>{isFr ? "ACCUEIL" : "HOME"}</NavLink><br />
            <DropdownMenu />
            <NavLink style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })} to='/organigramme'>{isFr ? "LE BDS" : "THE BDS"}</NavLink><br />
            {show_billetterie && <NavLink style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })} to='/billetterie'>{isFr ? "BILLETTERIE" : "EVENT SHOP"}</NavLink>}<br />
            <NavLink style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })} to='/calendar'>{isFr ? "CALENDRIER" : "CALENDAR"}</NavLink><br />
            <NavLink style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })} to='/arkose'>ARKOSE</NavLink><br />
            <NavLink style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })} to='/cotisation'>{isFr ? "COTISER" : "CONTRIBUTE"}</NavLink><br />
          </div>
        </div>
      </nav>

      {/* Menu Hamburger pour les petits écrans */}
      <nav className='bg-red-800 text-red-100 flex justify-between pl-4 pr-4 text-xl font-medium fixed w-full z-10 md:hidden'>
        <div className='flex items-center'>
          <img src={process.env.PUBLIC_URL + '/images/logobds.png'} alt="logo BDS Télécom PARIS" className='w-12 h-12' />
          <SwitchLanguage isFr={isFr} SwitchFrEn={SwitchFrEn} />
        </div>
        <div className='flex items-center'>
          <button onClick={toggleMenu} className='focus:outline-none'>
            <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </div>
      </nav>

      {/* Menu mobile */}
      {showMenu && (
        <div className="bg-red-800 text-red-100 fixed top-0 left-0 w-full h-screen z-20 flex flex-col items-center justify-center text-center">
          <NavLink className="p-4 text-2xl" onClick={toggleMenu} to='/'>{isFr ? "ACCUEIL" : "HOME"}</NavLink>
          <div className="p-4 text-2xl" onClick={toggleDropdown}>
            SPORTS
            {isDropdownOpen && (
              <div className="flex flex-col items-center">
                <NavLink className="p-4 text-2xl" onClick={toggleMenu} to='/sports'>{isFr ? "Les sports" : "Sports"}</NavLink>
                <NavLink className="p-4 text-2xl" onClick={toggleMenu} to='/edt'>{isFr ? "Emploi du temps" : "Schedule"}</NavLink>
              </div>
            )}
          </div>
          <NavLink className="p-4 text-2xl" onClick={toggleMenu} to='/organigramme'>{isFr ? "LE BDS" : "THE BDS"}</NavLink>
          {show_billetterie && <NavLink className="p-4 text-2xl" onClick={toggleMenu} to='/billetterie'>{isFr ? "BILLETTERIE" : "EVENT SHOP"}</NavLink>}<br />
          <NavLink className="p-4 text-2xl" onClick={toggleMenu} to='/calendar'>{isFr ? "CALENDRIER" : "CALENDAR"}</NavLink>
          <NavLink className="p-4 text-2xl" onClick={toggleMenu} to='/arkose'>ARKOSE</NavLink>
          <NavLink className="p-4 text-2xl" onClick={toggleMenu} to='/cotisation'>{isFr ? "COTISER" : "CONTRIBUTE"}</NavLink>
        </div>
      )}
    </>
  );
}
