import React, { useEffect, useState, FormEvent } from 'react';
import axios from 'axios';

type Editcodeform = {
    id: string
}


const EditCodeArkoseForm: React.FC<Editcodeform> = ({id}) => {
  const [code, setCode] = useState('');
  const [uuid, setUuid] = useState('0');
  const [status, setStatus] = useState('free');

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.post('/api/arkose/update', {id, code, uuid, status});
      alert('Code edited successfully');
    } catch (err) {
      alert('Error editing code');
    }
  };


  useEffect(() => {
  const data = axios.get(`/api/arkose/codes/${id}`)
                        .then((code) => {console.log("on reçoit "+code) ; setUuid(code.data.uuid) ; setStatus(code.data.status); setCode(code.data.code);})
                        .catch((error) => console.log(error));
  }, []);

  return (
    <main>
    <form onSubmit={handleSubmit} className='max-w-md mx-auto bg-white p-8 border border-gray-300 rounded-lg shadow-lg space-y-4'>
      <h3 className='font-semibold text-lg'>Modifier le code</h3>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Code</label>
        <input type="text" value={code} onChange={(e) => setCode(e.target.value)} required  className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'/>
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>UUID</label>
        <input type="text" value={uuid} onChange={(e) => setUuid(e.target.value)} required  className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'/>
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Statut</label>
        <input type="text" value={status} onChange={(e) => setStatus(e.target.value)}  className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'/>
      </div>
      <button type="submit" className='w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200'>Modifier le code</button>
    </form>
    </main>
  );
};

export default EditCodeArkoseForm;
