import React, { FormEvent, useState } from 'react';
import axios from 'axios';

const AddCodeArkoseForm = () => {
  const [code, setCode] = useState('');
  const [uuid, setUuid] = useState('0');
  const [status, setStatus] = useState('free');
  const [isVisible, setIsVisible] = useState(false);
  const toggleForm = () => {
    setIsVisible(!isVisible);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const newCode = { code, uuid, status };
      await axios.post('/api/arkose/codes', newCode);
      alert('Code added successfully');
      setCode('');
      setUuid('0');
      setStatus('free');
    } catch (err) {
      alert('Error adding code');
    }
  };

  return (
    <main>
    <button className='text-lg px-6 py-2 my-3 text-white font-semibold bg-blue-500 rounded-lg shadow-md hover:bg-blue-600 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 transition duration-200' onClick={toggleForm}>Ajouter un nouveau code</button>
    
    {isVisible && <form onSubmit={handleSubmit} className='max-w-md mx-auto bg-white p-8 border border-gray-300 rounded-lg shadow-lg space-y-4'>
      <h3 className='font-semibold text-lg'>Ajouter un nouveau code Arkose</h3>
      <p>Il faut mettre uuid à '0' et statut à 'free' quand on crée un nouveau code</p>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Code</label>
        <input type="text" value={code} onChange={(e) => setCode(e.target.value)} required  className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'/>
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>UUID</label>
        <input type="text" value={uuid} onChange={(e) => setUuid(e.target.value)} required  className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'/>
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Statut</label>
        <input type="text" value={status} onChange={(e) => setStatus(e.target.value)}  className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'/>
      </div>
      <button type="submit" className='w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200'>Ajouter Code</button>
    </form>}
    </main>
  );
};

export default AddCodeArkoseForm;
