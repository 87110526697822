import React from 'react';
import { SportCard } from '../components/Sport_card';

interface ScreenProps{
    isFr: boolean;
    SwitchFrEn: ()=>void;
    isConnected: boolean;
    SwitchConnection: ()=>void;
    queryParams: URLSearchParams;
  }

export const SportsEdt: React.FC<ScreenProps> = ({isFr, SwitchFrEn, isConnected, SwitchConnection, queryParams} ) => {
    return (
        <main>
            <div className='pt-24 m-5'>
                <p>L'emploi du temps arrive, pour l'instant vous pouvez vous rendre sur notre page Instagram <a className="font-bold" href="https://instagram.com/bdstelecomparis">@bdstelecomparis</a> pour voir tous les horaires.</p>
            </div>
        </main>
    )
}
