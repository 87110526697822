import React from 'react';
import NextEvent from '../components/NextEvent';
import NextEventList from '../components/NextEventList';

const motDuPrezFr = "Bienvenue sur le site du Bureau des Sports de Telecom Paris ! Nous vous offrons une vaste gamme de sports afin que chacun puisse trouver sa place et s\'épanouir pleinement tout au long de sa vie étudiante.\nNous organisons également des événements sportifs majeurs tels que l\'Ekiden (un marathon en relais autour du plateau), ainsi que des compétitions inter-écoles comme la Coupe de l\'X, le CCL, le Cartel des Mines et le TOSS.\n\nEn tant que président du BDS, ma mission principale est de fédérer le plus grand nombre d\'étudiants autour du sport et de promouvoir des valeurs communes de solidarité, de respect et de dépassement de soi.\n\nQue vous soyez débutant ou athlète confirmé, notre objectif est de vous offrir un accès facile et de qualité à la pratique sportive.\nJe suis très enthousiaste à l\'idée de vous représenter cette année et de vous offrir la meilleure expérience sportive possible !\n\nSportivement,\n\nMathieu BOUTEILLER\nPrésident du Bureau des Sports, Telecom Paris";
const motDuPrezEn = "Welcome on the website of the BDS of Télécom Paris ! We offer a large variety of sports so that anyone can find its place and thrive during their student life.\nWe also organise important sport events such as the Ekiden (a relay marathon around the plateau) and inter-school competitions such as the X Cup (X = École Polytechnique), the CCL, the Mines Cartel and the TOSS.\n\nAs the president of the BDS, my main mission is to federate as much students around sport as possible, and to promote values such as solidarity, respect and surpassing oneself.\nWether you are a beginner or a trained athlete, our goal is to offer you an easy and great access to sport practice.\nI am thrilled by the idea of representing you this year and offering you the best sportive experience possible ! \n\nSportingly,\n\nMathieu BOUTEILLER\nPresident of the Sports Bureau, Télécom Paris"
interface ScreenProps{
  isFr: boolean;
  SwitchFrEn: ()=>void;
  isConnected: boolean;
  SwitchConnection: ()=>void;
  queryParams: URLSearchParams;
}

export const Home: React.FC<ScreenProps> = ({isFr, SwitchFrEn, isConnected, SwitchConnection} ) => {
  return (
      <div className="flex flex-row flex-wrap pl-8 pr-4 justify-between">
      <div className=" h-screen min-w-96 flex-grow pl-8 pt-24 max-w-5xl overflow-scroll no-scrollbar pr-8">
        <h1 className='text-slate-800 text-5xl font-medium mt-8 border-l-4 border-red-700 pl-8'>{isFr ? "Bienvenue sur le site du BDS de Télécom Paris !" : "Welcome to the website of Télécom Paris' Sports Bureau (BDS)"}</h1>
        <p className='text-gray-600 font-light mt-8 text-lg'>{isFr ? "Le BDS de Telecom Paris est une association sportive qui propose une grande variété d'activités pour tous les étudiants de l'école. Que vous soyez un athlète confirmé ou débutant, vous trouverez certainement une activité qui vous convient." : "The BDS is an association that offers a lot of activities for every student of the school. Wether you are a confirmed athlete or a beginner, you will most likely find an activity that suits you."}</p>
        <img className='rounded-lg mt-8' src={process.env.PUBLIC_URL + "/images/natation.jpg"} alt="" />
        <p className='flex justify-end font-light'>{isFr ?"Cours de Natation à la piscine de polytechnique" : "Swimming lessons in the Ecole Polytechnique swimming pool"}</p>
        <div id= "mot du président" className='bg-white shadow-lg p-6 mt-8 rounded-lg'>
          <div className=' mb-8 flex justify-between'>
            
            <h2 className='font-medium text-4xl pt-4 border-b pr-20 border-gray-300'>{isFr ? "Message du Président" : "Word of the President"}</h2>
            <img className='rounded-full w-24 border-2 border-red-600' src={process.env.PUBLIC_URL + "/images/pp_mathieu.jpg"} alt="" />
          </div>
          
          <p className='font-light text-lg'>
          {isFr ? motDuPrezFr : motDuPrezEn}
          </p>
        </div>
      </div>
      <div className="h-screen flex flex-col justify-center p-2">
        <NextEventList isFr={isFr}/>
      </div>
      </div>
    )
}