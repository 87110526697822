import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import axios from 'axios';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

type Event = {
  _id: string;
  nom: string;
  date_debut: Date;
  date_fin: string;
  heure_debut: string;
  heure_fin: string;
  lieu: string;
  details_short: string;
  details: string;
  prix_cotisant: number;
  prix_non_cotisant: number;
  event_de_billetterie: boolean;
  lien_cagnotte: string;
}

type CalendarEvent = {
  title: string;
  start: Date;
  end: Date;
  location: string;
}


const CalendarPage = () => {
  const [events, setEvents] = useState<CalendarEvent[]>([]);

  useEffect(() => {
    // Fetch events from the backend
    axios.get('/api/events')
      .then(response => {
        // Map the events to the format react-big-calendar expects
        const events = response.data.map((event: Event) => ({
          title: event.nom,
          start: new Date(event.date_debut),
          end: new Date(event.date_fin),
          location: event.lieu
        }));
        setEvents(events);
      })
      .catch(error => console.error('Error fetching events:', error));
  }, []);

  return (
    <div className='pt-24'>
      <h2 className='mx-3'>Retrouvez ici tous les événements à venir</h2>
      <Calendar<CalendarEvent, object> className='m-3'
        localizer={localizer}
        events={events}
        startAccessor={(event) => event.start}
        endAccessor={(event) => event.end}
        style={{ height: 500 }}
        onSelectEvent={(event: CalendarEvent) => alert(`Event: ${event.title}\nLocation: ${event.location}`)}
      />
    </div>
  );
};

export default CalendarPage;
