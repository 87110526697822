import React, { FormEvent, useEffect, useState } from 'react';
import axios from 'axios';

type Edittransactionform = {
    idd: string
}

const EditTransactionForm: React.FC<Edittransactionform> = ({idd}) => {
  const [name, setName] = useState('');
  const [date, setDate] = useState<string>('');
  const [value, setValue] = useState('');
  const [details, setDetails] = useState('');
  const [id, setId] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const newTransaction = { id, name, date, value, details, status };
      await axios.post('/api/transactions/update', { idd, ...newTransaction});
      alert('Transaction added successfully');
    } catch (err) {
      alert('Error adding transaction');
    }
  };
  
  useEffect(() => {
    const data = axios.get(`/api/transactions/${idd}`)
                          .then((transaction) => {console.log("on reçoit "+transaction) ; setId(transaction.data.id) ;
                            console.log(transaction.data.date); setName(transaction.data.name) ; 
                            const formattedDate = transaction.data.date.split('T')[0] ; setDate(formattedDate) ;
                            setValue(transaction.data.value) ; setDetails(transaction.data.details) ;
                            setStatus(transaction.data.status) ;})
                          .catch((error) => console.log(error));
    }, []);

  return (
    <menu>
    <form onSubmit={handleSubmit} className='max-w-md mx-auto bg-white p-8 border border-gray-300 rounded-lg shadow-lg space-y-4'>
      <div>
        <label className='block text-sm font-medium text-gray-700'>ID</label>
        <input type="text" value={id} onChange={(e) => setId(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Nom</label>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Date</label>
        <input type="date" value={date} onChange={(e) => setDate(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Montant</label>
        <input type="number" value={value} onChange={(e) => setValue(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Details</label>
        <input type="text" value={details} onChange={(e) => setDetails(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Status</label>
        <input type="text" value={status} onChange={(e) => setStatus(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <button type="submit" className='w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200'>Ajouter Transaction</button>
    </form>
    </menu>
  );
};

export default EditTransactionForm;
