import './Profile_card.css';
import React, { useState } from 'react';


type ProfileProps = {
    profilePic: string
    name: string
    role: string
    poles: string[]
}

type polesDivProps = {
    poles: string[]
}

//TODO: rendre les poles cliquables pour afficher seulement l'organigramme du pole en question
const PolesDiv: React.FC<polesDivProps> = ({ poles }) => {
    return (
        <div className='polesDiv'>
            {poles.map((element, index) => (
                <div key={index}>{element}</div>
            ))}
        </div>
    );
};

export const Card: React.FC<ProfileProps> = ({ profilePic, name, role, poles }) => {
    const [flipped, setFlipped] = useState(true);
    const [hovered, setHovered] = useState(false);
    const handleClick = () => {
        setFlipped(!flipped);
    };


    return (
        <div className={` ${flipped ? 'flipped' : ''}`}
            onClick={handleClick}
            style={{ zIndex: hovered ? 2 : 1 }}
            onMouseEnter={(e) => {
                setHovered(true);
            }}
            onMouseLeave={(e) => {
                setHovered(false);
            }}
        >
            {flipped ? (
                <div className='card zoom-in-hover'>
                    <div className='front'>
                        <div className="flex flex-col items-center justify-center">
                            <h2 className="font-bold text-lg mb-2 text-white">{name}</h2>
                            <img src={process.env.PUBLIC_URL + `/images/${profilePic}`} alt={name} className="pp" />
                            <p className="text-gray-700 mt-2 text-sm">{(role!='Listeux' && role!='Listeuse') && role}</p>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='card zoom-on-hover'>
                    <div className="back">
                        <div className="flex flex-col items-center justify-center ">
                            <h2 className="font-bold text-lg mb-2 text-white">{name}</h2>
                            <p className="p text-gray-700 mt-2 text-sm">
                                {(role!='Listeux' && role!='Listeuse') && role}
                            </p>
                            <PolesDiv poles={poles} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Card;
