import React, { FormEvent, useState } from 'react';
import axios from 'axios';

const AddTransactionForm = () => {
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [value, setValue] = useState('');
  const [details, setDetails] = useState('');
  const [id, setId] = useState('');
  const [status, setStatus] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const toggleForm = () => {
    setIsVisible(!isVisible);
  };
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const newTransaction = { id, name, date, value, details, status };
      await axios.post('/api/transactions', newTransaction);
      alert('Transaction added successfully');
      setName('');
      setDate('');
      setValue('');
      setDetails('');
      setId('');
      setStatus('');
    } catch (err) {
      alert('Error adding transaction');
    }
  };

  return (
    <menu>
    <button className='text-lg px-6 py-2 my-3 text-white font-semibold bg-blue-500 rounded-lg shadow-md hover:bg-blue-600 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 transition duration-200' onClick={toggleForm}>Ajouter une nouvelle transaction</button>
    {isVisible && <form onSubmit={handleSubmit} className='max-w-md mx-auto bg-white p-8 border border-gray-300 rounded-lg shadow-lg space-y-4'>
      <div>
        <label className='block text-sm font-medium text-gray-700'>ID</label>
        <input type="text" value={id} onChange={(e) => setId(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Nom</label>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Date</label>
        <input type="date" value={date} onChange={(e) => setDate(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Montant</label>
        <input type="number" value={value} onChange={(e) => setValue(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Details</label>
        <input type="text" value={details} onChange={(e) => setDetails(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Status</label>
        <input type="text" value={status} onChange={(e) => setStatus(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <button type="submit" className='w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200'>Ajouter Transaction</button>
    </form>}
    </menu>
  );
};

export default AddTransactionForm;
