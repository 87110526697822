import React, { FormEvent, useEffect, useState } from 'react';
import axios from 'axios';

type Editsportform = {
  id: string
}

const EditSportForm: React.FC<Editsportform> = ({id}) => {

  const [namefr, setNamefr] = useState('');
  const [nameen, setNameen] = useState('');
  const [respo, setRespo] = useState('');
  const [descriptionfr, setDescriptionfr] = useState('');
  const [descriptionen, setDescriptionen] = useState('');
  const [horairefr, setHorairefr] = useState('');
  const [horaireen, setHoraireen] = useState('');
  const [image, setImage] = useState<File | null>(null);  // Re-enable image state
  const [localisation, setLocalisation] = useState('');
  const [loc_lien, setLoc_lien] = useState('');

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();


    try {
      const newSport = { namefr,nameen,respo,descriptionfr,descriptionen,horairefr,horaireen,localisation,loc_lien};
      await axios.post('/api/sports/update', { id, ...newSport});
      alert('Sport edited successfully');
    } catch (err) {
      console.error(err);
      alert('Error editing sport');
    }
  };
  
  useEffect(() => {
    const data = axios.get(`/api/sports/${id}`)
                          .then((sport) => {console.log("on reçoit "+sport) ; 
                            setNamefr(sport.data.namefr) ; setNameen(sport.data.nameen) ; setRespo(sport.data.respo) ;
                            setDescriptionen(sport.data.descriptionen) ; setDescriptionfr(sport.data.descriptionfr) ;
                            setHorairefr(sport.data.horairefr) ; setHoraireen(sport.data.horaireen) ;
                            setImage(sport.data.img_src) ; setLocalisation(sport.data.localisation) ;
                            setLoc_lien(sport.data.loc_lien) ;})
                          .catch((error) => console.log(error));
    }, []);

  return (
    <main>
    <form onSubmit={handleSubmit} className='max-w-md mx-auto bg-white p-8 border border-gray-300 rounded-lg shadow-lg space-y-4'>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Nom français</label>
        <input type="text" value={namefr} onChange={(e) => setNamefr(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Nom anglais</label>
        <input type="text" value={nameen} onChange={(e) => setNameen(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Responsable</label>
        <input type="text" value={respo} onChange={(e) => setRespo(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Description française</label>
        <textarea value={descriptionfr} onChange={(e) => setDescriptionfr(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Description anglais</label>
        <textarea value={descriptionen} onChange={(e) => setDescriptionen(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Horaire français</label>
        <textarea value={horairefr} onChange={(e) => setHorairefr(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Horaire anglais</label>
        <textarea value={horaireen} onChange={(e) => setHoraireen(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Localisation</label>
        <textarea value={localisation} onChange={(e) => setLocalisation(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' required />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Lien maps</label>
        <textarea value={loc_lien} onChange={(e) => setLoc_lien(e.target.value)} className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50' />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>Image</label>
        <input 
          type="file" 
          onChange={(e) => setImage(e.target.files![0])}  // Handle file input
          accept="image/*"  // Restrict to image files 
          className='mt-1 block w-full border border-gray-300 rounded-lg cursor-pointer focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
        />
      </div>
      <button type="submit" className='w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-200'>Modifier Sport</button>
    </form>
    </main>
  );
};

export default EditSportForm;
